import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import List from "./List";
import general from "../../general";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

const menus = [
  // {
  //   id: 'recovery',
  //   text: '小号回收',
  //   url: '/recovery',
  //   user: true,
  // },
  // {
  //   id: 'submit',
  //   text: '我要卖号',
  //   url: '/trade/submit',
  //   user: true,
  // },
  {
    id: 'record',
    text: '交易记录',
    url: '/trade/record',
    user: true,
  },
  {
    id: 'notice',
    text: '交易须知',
    url: '/trade/notice',
    user: true,
  },
  {
    id: 'service',
    text: '联系客服',
    url: '/service',
  }
];

export default props => {
  // useEffect(() => {
  //   props.setOperate(<Link to="/trade/notice" className="trade-index-notice">交易须知</Link>);
  // }, []);
  const [trades, setTrades] = useState([]);
  const load = () => {
    Dialog.loading();
    general.axios.post('/user/trade/getTradeList', {
      member_id: undefined,
      username: undefined,
      game_device_type: 2,
    })
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setTrades(data.featured_list);
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    load();
  }, []);

  return <div className="trade-index">
    <div className="trade-menu trade-inside-menu">
      <div className="items">
        {menus.map(item => <NavLink to={item.url} user={item.user} className="item">
          <img src={'/resources/trade/' + item.id + '.png'} alt=""/>
          {item.text}
        </NavLink>)}
      </div>
    </div>
    <div className="trade-body">
      <div className="trade-index-good">
        <div className="title">
          <div className="text">官方精选</div>
          <Link to="/trade/good" className="more">更多</Link>
        </div>
        <div className="items">
          {trades.map(trade => <Link key={trade.trade_id} to={'/trade/detail?id=' + trade.trade_id} className="item">
            <div className="icon"><Image src={trade.game_icon}/></div>
            <div className="info">
              <div className="title">{trade.game_name}</div>
              <div className="name">{trade.title}</div>
              <div className="price">{trade.trade_price}</div>
            </div>
          </Link>)}
        </div>
      </div>
      <List active={props.active}/>
    </div>
  </div>;
};