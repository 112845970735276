import React, {useEffect, useState} from "react";
import URI from "urijs";

function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
    break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
    break;
    default:
      return 0;
    break;
  }
}

export default props => {
  const [initProps, setInitProps] = useState({});
  const [newUrl, setNewUrl] = useState();
  const [progress, setProgress] = useState(randomNum(25, 50));
  useEffect(() => {
    setInitProps({user: true, token: true, ...props});
  }, [props]);
  useEffect(() => {
    if (localStorage.getItem('username')){
      let url = URI(props.valueInfo)
        .addQuery('username', localStorage.getItem('username'))
        .addQuery('token', localStorage.getItem('token'))
        .addQuery('location_url', window.location.origin)
        .addQuery('origin', 'react')
        .toString();
      setNewUrl(url);
    } else {
      let url = URI(props.valueInfo)
        .addQuery('location_url', window.location.origin)
        .addQuery('origin', 'react')
        .toString();
      setNewUrl(url);
    }
  }, [initProps]);

  //刷新iframe url
  useEffect(() => {
    if (props.active) {
      const iframe = document.getElementById("myActiveIframe");
      if (iframe && iframe.contentWindow) {
        console.log('shua xin')
        iframe.contentWindow.location.replace(iframe.src);
      }

      // const handlePopState = () => {
      //   console.log("hou tui");
      //   if (iframe && iframe.contentWindow) {
      //     iframe.contentWindow.location.replace(iframe.src);
      //   }
      // };

      // window.addEventListener("popstate", handlePopState);

      // return () => {
      //   window.removeEventListener("popstate", handlePopState);
      // };
    }
  }, [props.active]);

  return <div className="view-iframe" style={{
      paddingTop:'85px',
      height:'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 55px)',
    }}>
  <div className={'progress ' + (progress === 100 ? 'loaded' : 'loading')} style={{width: progress + '%'}}/>
  <iframe id="myActiveIframe" src={newUrl} onLoad={() => setProgress(100)}/>
</div>;
};
