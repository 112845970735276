import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import {PlayCircleOutlined} from '@ant-design/icons';
import {useScroll} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import {NavLink} from "../../../lib/Navigation";
import useActiveState from "../../../lib/useActiveState";
import Subtitle from "../../components/Subtitle";

export default props => {
  const {aplus_queue} = window;
  const [agent] = useActiveState('agent');
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [user] = useActiveState('user');
  const [randomColorInfo] = useActiveState('randomColorInfo');
  
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!init) {
      Dialog.loading();
    }
    let tmp = {
      type: 3,
      game_species_type: props.type,
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/reserveGames', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
          parms.finishPullUp && parms.finishPullUp();
          parms.finishPullDown && parms.finishPullDown();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          result.game_list.forEach(d => {
            d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
          })
          setGames(games => parms.page === 1 || !parms.page ? result.game_list : [...games, ...result.game_list]);
          setMore(result.paginated.more);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  //新游游戏点击埋点
  const clickGameOfTheNewGame = (game) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickGameOfTheNewGame', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), tabname: '新游预约', gamename:game.game_name, agent: agent}]
    });
  }

  const reserve = (game) => {    
    general.axios.post(game.is_reserved ? '/cancelReserveGame' : '/reserveGame', {id: game.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let result = res.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          let message = game.is_reserved ? '取消成功' : '预约成功';
          Dialog.success(message);
          const newGame = games.filter(item =>{
            if (item.maiyou_gameid == game.maiyou_gameid){
              item.is_reserved = !item.is_reserved;
            }
            return item.maiyou_gameid == game.maiyou_gameid;
          })
          console.log(newGame);
          setGames([...games],[...newGame])
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  };

  return <div className="new-reserve">
    {games.map((game,index) => <div key={index} className="new-reserve-item">
      <Link to={'/game?id=' + game.game_id + '&agent='+agent} className="subject" onClick={()=>clickGameOfTheNewGame(game)}>
        <div className="icon">
          <Image src={game.game_image.thumb}/>
          {/* <div className="scrole-stat">
            <img src="/resources/game/stat.png" />
            <span>{game.score}</span>
          </div> */}
        </div>
        <div className="text">
          <div className="name">
            <div className={"named " + (game.nameRemark ? " name-new" : "")}>{game.game_name}</div>
            {/* <div className="date">{moment(game.starting_time * 1000).format('MM-DD HH:mm')}首发</div> */}
            <div className="date">{game.label}</div>
          </div>
          <div className="info1">
            <img src="/resources/game/stat.png" />
            <span>{game.score}</span>
            {game.game_classify_type}
            <Subtitle>{game.nameRemark}</Subtitle>
            {/* <div className="date">{moment(game.starting_time * 1000).format('MM-DD HH:mm')}首发</div> */}
          </div>
          <div className="info2">
            {/* <div className="reserve">{game.reserved_total}人预约</div> */}
            {/* <div className="date">{game.label}</div> */}
            {/* {game.game_desc.split('+').map(item => <div className="tag">{item}</div>)} */}
            {/* {game.game_desc ? <>{game.game_desc?.split('+').map(item => <div className="tag">{item}</div>)}</> : <>{game.introduction}</>} */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <span className="tag"> <span className="tagtips"><div className="first-test">{item.slice(0,1)}</div><span className="other">{item.slice(1)}</span></span> </span>) : game.introduction} */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <span className="tag"><span className="tagtips"><span className="first-test">{item.slice(0,1)}</span>{item.slice(1)}</span> </span>) : <span style={{color: game.infoColor}}>{game.introduction}</span>} */}
            {/* {game.game_desc ? game.game_desc.split('+').map((item,index) => <span key={index} className="tag">{item}</span>) : <span style={{color: game.infoColor}}>{game.introduction}</span>} */}
          </div>
          <div className="info3">
            {
              game.bottom_lable ? 
              <>
              {Object.keys(game.bottom_lable).length > 0 ? 
              <div >
                <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
              </div> : null }
              </>
              : null
            }
            {/* <div className="reserve">{game.reserved_total}人预约</div> */}
            {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
            {game.is_support_accele ? <div className="speed">可加速</div> : null}
            {game.is_easy_play ? <div className="speed">省心玩</div> : null}
            </div>
        </div>
        
        <NavLink user={true} className={"btn " + (game.is_reserved ? 'disable' : '')} onClick={e=>{
          if (game.is_reserved){
            return
          }
          e.preventDefault()
          e.stopPropagation()
          reserve(game)
        }}>{game.is_reserved ? "已预约" : "预约"}</NavLink>
      </Link>
      <div className="medias clearfix">
        <Link to={'/video?url=' + game.video_url} className="media">
          <div className="container">
            <Image src={game.game_ur_list[0]} alt=""/>
            <PlayCircleOutlined className="play"/>
          </div>
        </Link>
        {[game.game_ur_list[0],game.game_ur_list[1],game.game_ur_list[2],game.game_ur_list[3]].map((img, index, imgs) => img ? <Link key={index} className="media" to={'/img?imgs=' + imgs + '&img=' + index}>
          <Image src={img} alt=""/>
        </Link> : null)}
      </div>
    </div>)}
    <NavLink to="/new/reserved" className="new-reserve-my-reserved">
      <span>我的预约</span>
    </NavLink>
    {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};