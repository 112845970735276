import {useEffect, useState} from "react";
import {StarFilled} from "@ant-design/icons";
import moment from "moment";
import general from "../../../general";
import Dialog from "../../../lib/Dialog";

export default props => {
  const [game, setGame] = useState({});
  // const [game, setGame] = useState(general.game);
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  // useEffect(() => {
  //   let game = props.game;
  //   if (game.kaifu_info) {
  //     game.kaifu_info = game.kaifu_info.map(item => {
  //       item.remind = localStorage.getItem('game-' + game.game_id + '-server-remind-' + item.starttime);
  //       return item;
  //     });
  //   }
  //   setGame(game);
  // }, [props.game]);

  useEffect(() => {
    if (game.kaifu_info) {
      game.kaifu_info = game.kaifu_info.map(item => {
        item.remind = localStorage.getItem('game-' + game.game_id + '-server-remind-' + item.starttime);
        return item;
      });
    }
    setGame(game);
  }, [game]);

  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    // selTestAgent();
    setLoading(true);
    Dialog.loading();
    let data = {};
    
    if (props.query.id) {
      data.game_id = props.query.id;
    } else {
      Dialog.error('缺少参数', 1, () => history.goBack);
      return;
    }

    if(!data.game_id) {
      if(!data.maiyou_gameid) {
        Dialog.error('缺少参数', 1, () => history.goBack);
        return;
      }
    }

    general.axios.post('/getGameInfo', data)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          let game = {...data, ...data.game_info};
          setGame(game);
          setInit(true);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const setRemind = index => {
    if (!general.isLogin()){
      PubSub.publish('alert-login',true)
      return
    }
    let kaifu_info = game.kaifu_info;
    kaifu_info[index].remind = !kaifu_info[index].remind;
    let key = 'game-' + game.game_id + '-server-remind-' + kaifu_info[index].starttime;
    if (kaifu_info[index].remind) {
      localStorage.setItem(key, true);
    } else {
      localStorage.removeItem(key);
    }
    setGame({...game, kaifu_info});
  }

  return <div className="game-server">
    <div className="tips">注：仅供参考，以游戏内开服为准！</div>
    {game.kaifu_info?.map((server, index) => <div className="item">

      {
        server.kaifuname == '动态开服' ? <>
          <div className="dynamic">
            <div className="img">
              <img src="/resources/game/service-dynamic-tips.png" />
              <div className="dtname">
                动态开服
              </div>
            </div>
            <div className="desc">
              上一区服玩家人数达到要求后自动开启下一区服，具体以游戏内区服为准
            </div>
          </div>
        </>
        :
        <>
          <div className="icon"><StarFilled/></div>
          {server.starttime != '0' ? 
          <div className="time">{moment(server.starttime * 1000).format('MM-DD HH:mm')}</div>
          : null}
          <div className="name">{server.kaifuname}</div>
          {server.starttime != '0' ? 
          <>
          {server.starttime * 1000 < moment().valueOf() ? <div className="operate disable">已开服</div> : <div>
            <div className="operate" onClick={() => setRemind(index)}>
              {server.remind ? '取消提醒' : '提醒'}
            </div>
          </div>}</>
          :null}
        </>
      }

      {/* <div className="icon"><StarFilled/></div>
      {server.starttime != '0' ? 
      <div className="time">{moment(server.starttime * 1000).format('MM-DD HH:mm')}</div>
      : null}
      <div className="name">{server.kaifuname}</div>
      {server.starttime != '0' ? 
      <>
      {server.starttime * 1000 < moment().valueOf() ? <div className="operate disable">已开服</div> : <div>
        <div className="operate" onClick={() => setRemind(index)}>
          {server.remind ? '取消提醒' : '提醒'}
        </div>
      </div>}</>
      :null} */}
    </div>)}
  </div>;
};