import React, {useEffect, useState, useRef, useCallback} from "react";
import general from "../../general";
import moment from "moment";
import $ from "jquery";
import {Link} from "react-router-dom";
import {useScroll, useReactive} from "ahooks";
import Dialog from "../../lib/Dialog";
import KeepScroll from "../../lib/KeepScroll";

const types = {
  0: '其它',
  2: '游戏攻略',
  3: '独家永久',
  4: '独家限时',
  5: '永久活动',
  6: '限时活动'
};

const status = {
  0: '未开始',
  1: '进行中',
  2: '已结束',
};

const router = [
  {
    type:'activity',
    title:'活动',
  },
  {
    type:'raiders',
    title:'攻略',
  }
];

const acType = {
  1: '单日累充',
  2: '多日累充',
  3: '单笔充值',
  4: '高额返利',
}

export default props => {
  const [typeIndex, setTypeIndex] = useState(0);
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [articles, setArticles] = useState([]);
  const [more, setMore] = useState(true);
  const [cutState, setCutState] = useState(false);

  const [acTypeIndex, setAcTypeIndex] = useState(0);
  const [typeRouterList, setTypeRouterList] = useState([]);

  const datas = useReactive(router.map(item => ({
    type: item.type,
    title: item.title,
    init: false,
    loading: false,
    page: 1,
    more: null,
    datas: [],
    loadState: false,
  })));

  const disBut = () => {
    // Dialog.loading();
    let tmp = {
      // game_id: props.query.id,
      game_id: props.query?.id ? props.query.id : props.game.game_id,
      type: 'raiders',
    };
    general.axios.post('/articlesV2', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let result = response.data.data;
        
        if(result.list.length > 0 && props?.query?.id) {
          setCutState(true);
        }
        load();
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(()=>{
    disBut();
  },[]);

  const load = (parms = {}) => {
    let data = datas[typeIndex];
    // console.log(data);
    if (data.loading) {
      return;
    }
    // setLoading(true);
    data.loading = true;
    if(props.query?.id) {
      Dialog.loading();
    }
    let tmp = {
      // game_id: props.query.id,
      game_id: props.query?.id ? props.query.id : props.game.game_id,
      // pagination: {page: page, count: 20},
      pagination: {count: 20, page: data.page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    // console.log(data.type);
    tmp.type = data.type;
    general.axios.post('/articlesV2', tmp)
        .finally(() => {
          // setLoading(false);
          data.loading = false;
          Dialog.close();
        })
        .then(response => {
          // setInit(true);
          data.init = true;
          let result = response.data.data;
          // if (parms.page) {
          //   setPage(parms.page);
          // }
          // setArticles(articles => parms.page === 1 || !parms.page ? result.list : [...articles, ...result.list]);
          // setMore(result.paginated.more);

          
          let typeRouterList = [];

          result.list.forEach(item => {
            if(item.name == "单日累充") {
              item.actype = 1;
            } else if(item.name == "多日累充") {
              item.actype = 2;
            } else if(item.name == "单笔充值") {
              item.actype = 3;
            } else if(item.name == "高额返利") {
              item.actype = 4;
            } else if(item.name == "其它类型") {
              item.actype = 5;
            }

            let a = {id:item.actype,title:item.name};
            typeRouterList.push(a);
          });

          if(typeIndex == 0) {
            setTypeRouterList(typeRouterList);
          }

          if (parms.page) {
            data.page = parms.page;
          }
          data.datas = parms.page === 1 || !parms.page ? result.list : [...data.datas, ...result.list];
          data.more = result.paginated.more;
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && data.init && data.more) {
        load({page: data.page + 1});
      }
    }
  }, [scroll, props.active]);


  
  const [mode, setMode] = useState();
  const timesRef = useRef(null);
  const [timeEl, setTimeEl] = useState();
  const timeRef = useCallback(node => {
  if (node) {
      setTimeEl(node);
  }
  }, []);
  const groupsRef = useRef(null);
  const [groupEl, setGroupEl] = useState();
  const groupRef = useCallback(node => {
      if (node) {
          setGroupEl(node);
      }
  }, []);

  //定位
  useEffect(() => {
    if (timesRef.current && timeEl && groupEl) {
      if (mode !== 'scroll') {
        $('html,body').animate({scrollTop: groupEl.offsetTop - (timesRef.current.offsetTop + timesRef.current.offsetHeight)}, 250);
      }
    }
  }, [acTypeIndex, mode, timesRef, timeEl, groupEl]);
  

  
  // console.log(datas);
  // useEffect(() => {
  //   if (props.active && !init) {
  //     load();
  //   }
  // }, [props.active]);

  // const scroll = useScroll();
  // useEffect(() => {
  //   if (props.active && init) {
  //     if (scroll.top < -80) {
  //       load({page: 1});
  //     }
  //     if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
  //       load({page: page + 1});
  //     }
  //   }
  // }, [scroll, props.active]);

  const [activeRouterListNum, setActiveRouterListNum] = useState(0);

  return <div className="game-article">
    {/***头部切换****/}
    {cutState ? 
    <div className="article-head">
      <div className="head-items">
        {router.map((item, index) => <div className={'head-item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>{item.title}</div>)}
      </div>
    </div>
    : null}

    
    {/** 类型切换 **/}
    {typeRouterList.length > 1 && typeIndex === 0 ?
    <div className={"article-actype " + (!cutState ? typeRouterList.length > 1 ? props?.query?.id ? 'only-activite-actype-new' : 'only-activite-actype-new-community' : ' only-activite-actype' : '') } ref={timesRef}>
      {
        typeRouterList?.map((item, index) => <div className={"actype-item" + (index === acTypeIndex ? ' active' : '')} ref={index === acTypeIndex ? timeRef : null} onClick={() => {setAcTypeIndex(index);setActiveRouterListNum(item.id)}}>
          {item.title}
        </div>)
      }
    </div>
    : <div style={{height:'15px'}}></div>}
    
    {/** 主体 **/}
    <div className={cutState ? (typeRouterList.length > 1 && typeIndex === 0 ? 'main-ac-items-actype ' : 'main-ac-items ') : ''}>
    {datas.map((item, index) =>
      <div className={'type-item' + (index === typeIndex ? ' active' : '') + (typeRouterList.length > 1 && typeIndex == 0 ?  typeRouterList.length > 4 ? ' activitty-ac-items ' : ' activitty-ac-items-first ' : '') + (!cutState ? ' only-activite-ac-items' : '') + (!props?.query?.id ? ' only-activite-ac-items-community ' : '') }>
        <KeepScroll active={index === typeIndex}>
          {
            item.datas.map((article, i) => <div className="actype-items " ref={article.actype == activeRouterListNum ? groupRef : null}>
              {item.type == 'activity' ?
              <div className="acticle-container" style={{borderRadius:props.community ? '0' : '13px'}}>
                {/* {
                  typeRouterList.length > 1 ?
                  <div className="actype-name">#{article.name}</div>
                  : null
                } */}
                <div className="actype-name">#{article.name}</div>
                {article?.child.map((acitem, ix) => <Link to={'/article/detail?id=' + acitem.news_id + '&type=' + item.type} className="item" onClick={() => general.article = article}>
                  <div className="container">

                    <div className="desc">
                      {acitem?.type ? 
                      <div className={"actype actype-" + (acitem.type ? acitem.type : 0) }>{types[acitem.type]}</div>
                      : null}
                      <div className="title">{acitem.news_title}</div>
                      
                      {/* <div className="time">{moment(acitem.news_date * 1000).format('YYYY-MM-DD')}</div> */}
                    </div>
                    <div className="desc2">
                      {/* <div className="state">
                        {item.type == 'activity' ?
                        <>{acitem.timeDesc}</>
                        : null}
                      </div> */}

                      <div className="state">
                        {item.type == 'activity' ?
                        <>{acitem?.statusRemark}</>
                        : null}
                      </div>

                      <div className="time">{acitem?.dateRange}</div>

                      {/* {acitem.type == 5 ? <div className="time">{acitem.timeDesc}</div> : null} */}
                      {/* <div className="time">{moment(acitem.news_date * 1000).format('YYYY-MM-DD')}</div> */}
                    </div>
                    {/* <div className="title">{acitem.news_title}</div>
                    <div className="desc1">{acitem.new_content}</div> */}
                    <div style={{display:article?.child.length == 1 || ix == article?.child.length-1 ? 'none' : ''}} className="line"></div>
                  </div>
                  <div className="more">
                    <img src="/resources/welfare/left-back.png" className="left-back"/>
                  </div>
                </Link>)}
              </div>
              :
              <>
              <Link to={'/article/detail?id=' + article.news_id + '&type=' + item.type} className={"item " + (item.type !== 'activity' ? ' gl-item ' : '')} onClick={() => general.article = article}>
                <div className="container">
                  {/* <div className={'type type-' + article.type}>{acType[article.acType == true ? 1 : article.acType]}</div> */}
                  {/* <div className="desc">
                    {acType[article.acType == true ? 1 : article.acType] ? 
                    <div className={'type actype-' + (article.acType == true ? 1 : article.acType)}>{acType[article.acType == true ? 1 : article.acType]}</div>
                    : null}
                    <div className="actype">{types[article.type]}</div>
                    <div className="state">
                      {item.type == 'exceptRaiders' ?
                      <>{article.status_title}</>
                      : null}
                    </div>
                    
                    <div className="time">{moment(article.news_date * 1000).format('YYYY-MM-DD')}</div>
                  </div> */}
                  {/* <div className="title">{article.news_title}</div> */}
                  <div className="desc1">{article.news_title}</div>
                </div>
                <div className="more">
                  <img src="/resources/welfare/left-back.png" className="left-back"/>
                </div>
              </Link></>
              }
            </div>)
          }
        </KeepScroll>
      </div>
    )}
    {/* {articles.map(article => <Link to={'/article/detail?id=' + article.news_id} className="item" onClick={() => general.article = article}>
      <div className="container">
        <div className="desc">
          <div className={'type type-' + article.type}>{types[article.type]}</div>
          <div className="state">{status[article.status]}</div>
          <div className="time">{moment(article.news_date * 1000).format('YYYY-MM-DD')}</div>
        </div>
        <div className="title">{article.news_title}</div>
      </div>
      <div className="more">></div>
    </Link>)}
    {articles.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null} */}
    </div>
  </div>;
};