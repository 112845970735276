import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useScroll, useLockFn, useMount} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import useActiveState from '../../lib/useActiveState';
import Subtitle from "../components/Subtitle";

export default props => {
  const {aplus_queue} = window;
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [init, setInit] = useState(false);
  const [data, setData] = useState();

  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios.post('/transferV2/index', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        setObsolete(false);

        parms.page && setPage(parms.page);
        let {items: list, paginated} = data;
        list.forEach(d => {
          d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
        })
        setData(data);
        setGames(games => parms.page === 1 || !parms.page ? list : [...games, ...list]);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useEffect(() => {
    props.setOperate(<Link to="/around/rule">转游说明</Link>);
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  return <div className="around-index">
    <div className="around-amound" style={{backgroundImage: 'url(/resources/around/amount-bg.png)'}}>
      <div className="title">我的转游点</div>
      <div className="amount">{data?.score == 0 ? 0 : data?.score?.toFixed(1)}</div>
      <div className="tips">充值金额可1:1兑换成转游点（仅计算2023.01.01后充值）</div>
    </div>

    <div className="around-record-tips">
      <div className="name">可兑换热门游戏</div>
      <Link className="record" to="/around/record" >转游记录<img src="/resources/welfare/left-back.png" /></Link>
    </div>

    <div className="items">
      {games.map((game, index) => <Link to={'/around/detail?id=' + game.transId} className="around-game-item" >
        <div className="item-base">
          <div className="icon">
            <Image src={game.logo.thumb}/>
          </div>
          <div className="subject">
            <div className="text">
              <div className="name">
                <div className={" " + (game.nameRemark ? " name-new" : "")}>
                  {game.topLable != '' ? <span className="span">{game.topLable }</span> : null }
                  {game.gameName}
                </div>
              </div>
              <div className="info1" style={{marginTop:(Object.keys(game.bottomLable).length > 0 ? '0' : '8px')}}>
                {game.versioncode}
                <Subtitle>{game.nameRemark}</Subtitle>
              </div>
              {/* <div className="info2">
                {game.version ? game.version.split('+').map(item => <div className="tag">{item}</div>) : <span style={{color: game.infoColor}}>{game.introduction}</span>}
              </div> */}
              {
                game.bottomLable ? 
                <>
                {
                  Object.keys(game.bottomLable).length > 0 ?
                  <div className="info3">
                    <span className="second-text" style={{color:game.bottomLable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottomLable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottomLable.right_content}</span>
                  </div>
                  :null
                }
                </>
              :null}
            </div>
            {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
          </div>
        </div>
        <div className="item-gift">
          {game?.countPack > 0 ? 
          <div className="gift-num">
            <div className="g-name">转游礼包</div>
            <div className="g-num">{game?.countPack}</div>
          </div>
          : null}
          {game?.countVoucher > 0 ?
          <div className="voucher-num">
            <div className="v-name">转游代金券</div>
            <div className="v-num">{game?.countVoucher}</div>
          </div>
          : null}
        </div>
      </Link>)}
      {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    </div>
  </div>;
};