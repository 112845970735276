import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import './css/Recycle.css'
import {CSSTransition} from "react-transition-group";
import { NavLink } from "../../../lib/Navigation";
import useActiveState from "../../../lib/useActiveState";
import Dialog from '../../../lib/Dialog';

export default function Recycle(props) {

  const [show,setShow] = useState(false);
  const [user] = useActiveState('user');
  const history = useHistory();

  const router = [
    {
      title:'出售',
      path:'/trade/submit',
      img:'resources/trade/recycle-chushou.png',
      desc:'闲置角色，出售换币'
    },
    {
      title:'购买',
      path:'/trade',
      img:'resources/trade/recycle-goumai.png',
      desc:'角色迁移，无需换绑'
    },
    {
      title:'回收',
      path:'/recovery',
      img:'resources/trade/recycle-huishou.png',
      desc:'官方回收，立享收益'
    },
    {
      title:'转游',
      path:'/around',
      img:'resources/trade/recycle-zhuanyou.png',
      desc:'转游福利，快人一步'
    } 
  ]

   //跳转
   const bindHred = (item) => {
    if(item.title == '回收') {
      if (!user.mobile) {
        Dialog.confirm({
          title: '没有绑定手机号，现在去绑定？',
          yes: () => {
            history.push('/user/phone');
          }
        });
      } else {
        history.push(item.path);
      }
    } else {
      history.push(item.path);
    }
  }

  return (
    <div className='recycle-index'>
      <div className="content">
        <div className="header">
          <img src="resources/trade/recycle-header.png" alt="" />
          <div onClick={()=>setShow(true)} className="contarner" style={{backgroundImage:'url(resources/trade/recycle-desc.png)'}}>
            <div className="left">
              <div>
                <img src="resources/trade/recycle-point.png" alt="" />
                <span>未成年无法参与</span>
              </div>
              <div>
                <img src="resources/trade/recycle-point.png" alt="" />
                <span>角色转移不涉及主账号买卖</span>
              </div>
            </div>
            <div className="right">查看详情</div>
          </div>
        </div>
        <div className="list">
          {router.map((item,index)=>{
            return (
              <NavLink user={true} onClick={()=>bindHred(item)} key={index}>
                <div className='item'>
                  <div className="left">
                    <img src={item.img} alt="" />
                    <div className='content'>
                      <div>{item.title}</div>
                      <span>{item.desc}</span>
                    </div>
                  </div>
                  <img className="right" src='resources/trade/recycle-more.png'></img>
                </div>
              </NavLink>
            )
          })}
        </div>
        <CSSTransition in={show} timeout={250} unmountOnExit={true}>
          <div className="alert">
            <div className="mask" onClick={()=>setShow(false)}></div>
            <div className="content">
              <div className="header">回收交易简介</div>
              <div className="item">
                <img src="resources/trade/recycle-desc-point.png" alt="" />
                <span className="group">【买卖】</span>
                <div className="title">小号买卖是采用转移小号的方式；买入或者卖出游戏角色，不影响您惠爪游戏账号的使用。</div>
                <div className="desc">什么是小号？<br/>
                  ①平台账号在每个游戏中可创建10个小号（第一个在首次登录时自动创建）；<br/>
                  ②不同游戏间的小号、和同一游戏下各个小号相互独立；<br/>
                  ③出售某一小号时，该小号在该游戏多个区服的角色将一并被出售。<br/></div>
              </div>
              <div className="item">
                <img src="resources/trade/recycle-desc-point.png" alt="" />
                <span className="group">【回收】</span>
                <div className="title">闲置的游戏小号不想玩了，可回收给平台换取金币用于其他游戏消费~</div>
              </div>
              <div className="item">
                <img src="resources/trade/recycle-desc-point.png" alt="" />
                <span className="group">【转游】</span>
                <div className="title">在平台游戏中，实付消费1:1产生转游点数，可用此来兑换转游礼包，助力您开始新的游戏征程。</div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}
