import { Avatar, Ellipsis } from 'antd-mobile'
import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react'
import './css/CommunityItem.css'
import general from '../../../../../general'
import Dialog from '../../../../../lib/Dialog'
import moment from 'moment'
import { BigPlayButton, ControlBar, Player, Shortcut } from 'video-react'
import { Link } from 'react-router-dom'
import Image from '../../../../../lib/Image'
import { NavLink } from '../../../../../lib/Navigation'

export default function CommunityItem(props) {

  const [item,setItem] = useState(props.item)
  const [like,setLike] = useState(item.me_like === '1')
  const [like_count,setLike_count] = useState(item.like_count)
  const [reply] = useState(props.reply === true)
  const [isfootMark] = useState(props.isfootMark)
  const [playing,setPlaying] = useState(false)
  const [content,setContent] = useState(item.content)
  const [showMore,setShowMore] = useState(false)
  const [isOpen,setIsOpen] = useState(false)
  const textContainer = useRef()
  const playerRef = useRef()
  // const textRef = useRef(null);

  const lickAction = (e)=>{
    if (like === true){
      return
    }
    const tmp = {
      tagid:item.tagid,
      id:item.id,
      tab:parseInt(item.tagid) === 3 ? 'question' : ''
    }
    general.axios.post('/base/community/likeCommunity',tmp)
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
      } else {
        setLike(true)
        setLike_count(parseInt(e) + 1)
        Dialog.toast({
          icon: 'agree',
          content: '',
          duration: 1,
          mask: false,
          onClose:()=>{},
        });
      }
    })
  }

  const replyLikeAction = (obj,e)=>{    
    if (obj.me_like === '1'){
      return
    }
    const tmp = {
      tagid:e.tagid,
      id:obj.id,
      tab:parseInt(item.tagid) === 3 ? 'answer' : ''
    }
    general.axios.post('/base/community/likeCommunity',tmp)
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
      } else {
        obj.me_like = '1'
        obj.like_count = parseInt(obj.like_count)+1
        console.log(e);
        setItem({
          ...item,
          reply_list:e.reply_list
        })
      }
    })
  }

  const formatTime = (time) => {
    var date = new Date(time * 1000)
    let Y = date.getFullYear()
    let M = date.getMonth()+1
    let D = date.getDate() < 10 ? '0'+date.getDate() : date.getDate()
    let H = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':'
    let m = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()
    var time = Y + '-' + M + '-' + D
    if (time === moment().format('YYYY-MM-DD')) {
      return '今日 ' + H + m;
    } else if (time == moment().subtract(1, 'd').format('YYYY-MM-DD')) {
      return '昨日 ' + H + m;
    } else {
      return M + '月' + D + '日' + H + m
    }
  }

  useEffect(()=>{
    if (playing){
      playerRef.current?.video.play()
    }else{
      playerRef.current?.video.pause()
    }
  },[playing])

  const formatChar = (char)=>{
    if (char === '/' || char === '-' || char === ':' || char === 'i' || char === '.'
           || char === ',' || char === '!' || parseInt(char) < 10){
      return 6
    }else if (/^[a-z]$/.test(char)){
      return 8
    }else if (/^[A-Z]$/.test(char)){
      return 10
    }else if (char === '，' || char === '。' || char === '！' || char === '%'){
      return 20
    }else{
      return 14.5
    }
  }

  useLayoutEffect(()=>{
    if (textContainer){
      const fontWidth = parseInt(window.getComputedStyle(textContainer.current).fontSize,10)
      const containerWidth = parseInt(window.getComputedStyle(textContainer.current).width,0)
      let width = 0;
      const fontNum = Math.floor(3 * containerWidth)
      for (let i =0;i<content.length;i++){
        const char = content[i]
        width += formatChar(char)
        if (width > fontNum) {
          const string = item.content.slice(0,i-7)
          setContent(string)
          setShowMore(true)
          return
        }
      }
    }
  },[content])

  return (
    <NavLink user={reply} className='community-item' onClick={()=>{
      if (props.commentAction){
        props.commentAction(item.id,item.user_nickname)
      }
    }}>
      <div className="community-item-container" style={{paddingBottom:reply && item.reply_list?.length > 0 ? '12px' : '0'}}>
        <div className="header">
          <NavLink to={!isfootMark ? '/user/record?id=' + item.user_id : ''}>
            <Avatar style={{borderRadius:isfootMark ? '10px' : '20px'}} className='avatar' src={isfootMark ? item.game_info.game_image.source : reply?item.user_logo:item.user_icon}></Avatar>
          </NavLink>
          <div className="name">
            <div className="username">{isfootMark ? item.game_name : item.user_nickname}
            {isfootMark && item.nameRemark ? <span className='name-remark'>{item.nameRemark}</span> : null}
            </div>
            <div className="time">{(()=>{
              if (isfootMark){
                return item.versioncode +'｜ ' + formatTime(item.time)
              }else{
                return formatTime(item.time)+` • 共发表` + item.user_community_count + '条游戏评论' + (parseInt(item.tagid) === 3 && !reply ? ' • ' + (item.ip_location?.province ? item.ip_location.province : '未知') : '')
              }
            })()}</div>
          </div>
          <div className='gold' style={{display:parseInt(item.reward_intergral_amount) > 0 ? '' : 'none'}}>
            <img className='icon' src={'resources/community/' + (parseInt(item.tagid) === 1 ? 'icon.png' : 'strategy.png')}/>
            <div className='number' style={{color:parseInt(item.tagid) === 1 ? '#8B4BEF' : '#4895FB'}}>奖励{parseInt(item.reward_intergral_amount)}金币</div>
          </div>
        </div>
        {item.title ? <div className='title'>{item.title}</div> : null}
        {/* <Ellipsis key={`ellipsis-${item.id}`} className="content" defaultExpanded={reply} content={item.content} direction='end' expandText='查看全文' rows={3}></Ellipsis> */}
        {/* <div className="content" >
        <span >{item.content.substring(0, 66).toString()}{item.content.length > 66 ? '...' : ''}</span>
        {item.content.length > 66 ?
        <span style={{paddingLeft:'.1rem',position:'absolute', right:'.7rem', color:'#999'}}>查看全文</span>
        : '' }
        </div> */}

        <div ref={textContainer} className={isOpen ? 'content allcontent' : 'content'} >
        {showMore ? (isOpen ? item.content : content) : item.content}
        {showMore ? <span style={{color:'#999'}} onClick={(e)=>{
          if (reply){
            e.preventDefault()
            e.stopPropagation()
            setShowMore(false)
            setIsOpen(!isOpen)
          }
        }}>{isOpen ? ' 点击收起' : '...查看全文'}</span> : null}
        </div>
        {item.video?.length > 0 ? 
          <div className="video" onClick={(e)=>{
              e.stopPropagation()
              e.preventDefault()
              setPlaying(!playing)
            }}>
          <Player
            poster={item.video_img_url}
            ref={playerRef}
            onPlay={()=>setPlaying(true)} onPause={()=>setPlaying(false)}
            autoPlay={false}
            playsInline={true}
            src={item.video}
          >
            <Shortcut></Shortcut>
            <BigPlayButton position='center'></BigPlayButton>
            <ControlBar disableDefaultControls={true}></ControlBar>
          </Player>
          <div className="playbtn" style={{visibility:playing ? 'hidden' : ''}}>
            <img src={'resources/community/' + (playing ? 'pause.png' : 'playing.png')} alt="" />
          </div>
        </div> :
        item.contentImageUrls?.length > 0 ? 
        <div className="wap">
          {/* <img className='image' src={item.contentImageUrls[0]}></img> */}
          <Link to={'/img?imgs=' + item.contentImageUrls[0] + '&img=0'} className="img"><Image className='image' src={item.contentImageUrls[0] ? item.contentImageUrls[0] : '/resources/placeholder/index-active.png'} alt="" style={{backgroundImage:'url(/resources/placeholder/index-active.png)'}} /></Link>
        </div> : null}
        {parseInt(item.tagid) === 3 && !reply ? 
          <div className="question">
            <div className="time">
            {item.latest_time ? <span>{formatTime(item.time)}最后回答 • </span> : null}
            <span>共{item.reply_count}条回答</span>
            {isfootMark ? 
              <span className='footmark-type' 
                style={{
                  color:parseInt(item.recover) == 2 ? '#FFC000' : '#5494F7',
                  backgroundColor:parseInt(item.recover) == 2 ? '#FFF9E5' : '#ECF6FF',
                }}>{parseInt(item.recover) == 2 ? '#回复' : '#提问'}
              </span> : null}
            </div>
            <div className="answer">我来回答</div>
          </div> : 
          <div className="bottom">
            <div className="ip">IP • {item.ip_location ? '' + item.ip_location.province : '未知'}
            {parseInt(props.tagid) === -1 || isfootMark ? 
            <span className='iptype' 
                  style={{
                    padding:'2px 3px',
                    borderRadius:'3px',
                    marginLeft:'5px',
                    backgroundColor:parseInt(item.recover) == 2 ? '#F5F2FB' : parseInt(item.tagid) === 1 ? '#F2F6EA' : '#FDECEF',
                    color:parseInt(item.recover) == 2 ? '#9B84D6' : parseInt(item.tagid) === 1 ? '#99C14B' : '#EB475F'}}>
            {parseInt(item.recover) === 2 ? '#回复' : (parseInt(item.tagid) === 1 ? '#评论' : '#攻略')}
            </span> : null}
            </div>
            <div className="action">
              <NavLink user={true} onClick={(e)=>{
                  e.stopPropagation()
                  e.preventDefault()
                  lickAction(like_count)
                }}>
                <div className="btn" >
                  <img src={'resources/community/' + (like ? 'liked.png' : 'like.png')} width='17px'></img>
                  <div className='number'>{like_count}</div>
                </div>
              </NavLink>
              <div className="btn">
                <img src='resources/community/comment.png' width='17px'></img>
                <div className='number'>{item.reply_count}</div>
            </div>
          </div>
        </div>
        }
        {item.reply_list?.length > 0 ? 
          reply ? 
          <div className="reply-container">
          {item.reply_list.map((obj,idx)=>{
            return (
              <div className='reply-item'>
                <div className="userinfo">
                  <img src={obj.user_logo} alt="" />
                  &nbsp;<span>{obj.reply_nickname}</span>
                </div>
                <div className="reply-content">{obj.content}</div>
                <div className="action">
                  <div className="ip">
                    {obj.ip_location ? <span>IP • {obj.ip_location.province}&nbsp;&nbsp;</span> : null}
                    <span>{formatTime(obj.time)} • 共发表{obj.user_community_count}条游戏评论</span>
                  </div>
                  <NavLink user={true} onClick={(e)=>{
                      e.stopPropagation()
                      e.preventDefault()
                      replyLikeAction(obj,item)
                    }}>
                    <div className="like" >
                      <img src={'resources/community/' + (obj.me_like === '1' ? 'liked.png' : 'like.png')} width='12px' alt="" />
                      &nbsp;&nbsp;<span className='number'>{obj.like_count}</span>
                    </div>
                  </NavLink>
                </div>
                {idx < item.reply_list.length-1 ? 
                <div className='line'></div> : null}
              </div>
            )
          })}
          </div> : 
          <>
            <div className="reply">
              <span className='username'>{item.reply_list[0].user_nickname}:</span>
              <span className='reply-contents'>{item.reply_list[0].content}</span>
            </div>
            <div style={{height:'12px'}}></div>
          </> : null
        }
      </div>
    </NavLink>
  )
}
