import {useCallback, useEffect, useRef, useState} from "react";
import {QuestionCircleOutlined, RightOutlined, SyncOutlined, PlayCircleOutlined, UpOutlined, DownOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useLocalStorageState, useReactive, useSize} from "ahooks";
import general from "../../../general";
import {NavLink} from "../../../lib/Navigation";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import { CSSTransition } from "react-transition-group";
import GameWelfare from "../GameWelfare";
import GameDesc from "../GameDesc";
import Manager from "../../components/Manager";
import URI from "urijs";

export default props => {
  const [game, setGame] = useState({});
  useEffect(() => {
    setGame(props.game);
  }, [props.game]);

  const [welfareMore, setWelfareMore] = useState(true);
  const [welfareMored, setWelfareMored] = useState(false);
  const welfareBodyRef = useRef();
  const welfareEntityRef = useRef();
  const welfareBodySize = useSize(welfareBodyRef);
  const welfareEntitySize = useSize(welfareEntityRef);

  useEffect(() => {
    if(welfareEntitySize.height && welfareBodySize.height) {
      !welfareMored && setWelfareMore(welfareEntitySize.height > welfareBodySize.height);
    }
  }, [welfareBodySize, welfareEntitySize]);

  const [rebateMore, setRebateMore] = useState(true);
  const [rebateMored, setRebateMored] = useState(false);
  const rebateBodyRef = useRef();
  const rebateEntityRef = useRef();
  const rebateBodySize = useSize(rebateBodyRef);
  const rebateEntitySize = useSize(rebateEntityRef);
  useEffect(() => {
    if(rebateEntitySize.height && rebateBodySize.height) {
      !rebateMored && setRebateMore(rebateEntitySize.height > rebateBodySize.height);
    }
  }, [rebateBodySize, rebateEntitySize]);

  const [introMore, setIntroMore] = useState(true);
  const [introMored, setIntroMored] = useState(false);
  const introBodyRef = useRef();
  const introEntityRef = useRef();
  const introBodySize = useSize(introBodyRef);
  const introEntitySize = useSize(introEntityRef);
  useEffect(() => {
    if(introEntitySize.height && introBodySize.height) {
      !introMored && setIntroMore(introEntitySize.height > introBodySize.height);
    }
  }, [introBodySize, introEntitySize]);

  const [companyMored, setCompanyMored] = useState(false);

  const [player, setPlayer] = useState();
  const [muted, setMuted] = useLocalStorageState('game-video-muted', true);
  const video = useReactive({});
  const [videoPlayState, setVideoPlayState] = useState();
  const [game_info_show,setGame_info_show] = useState();

  const onPlaying = () => {
    video.play = true;
    video.eventPause = false;
    video.activePause = false;
  }
  const onPause = () => {
    video.play = false;
    video.eventPause = true;
  }

  const imgRef = useCallback(node => {
    if (node) {
      node.addEventListener('touchstart', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchmove', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchend', e => {
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);

  const [data, setData] = useState([]);
  const [init, setInit] = useState(false);

  const recommend = () => {
    if(!game.game_id) {
      return;
    }
    // Dialog.loading();
    general.axios.post('/base/newindex/GameLike', {game_id: game.game_id})
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        setData(data.list);
        // props.setGame(game => ({...game, recommend_info: data.list}));
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(()=>{
    // recommend();
    if(game && !init) {
      recommend();
    }
  }, [game])

  // console.log(game.game_feature_list ? game.game_feature_list[0].content : null);
  const [teseState, setTeseState] = useState(false);
  useEffect(() => {
    if(game.game_feature_list) {
      // console.log(game.game_feature_list[0].content);
      if(game.game_feature_list[0].content.trim()) {
        setTeseState(true);
      }
    }
  }, [game.game_feature_list]);

  // console.log(teseState);

  return <div className="game-index">
    
    {game.game_feature_list && game.game_feature_list[0].content.trim().length > 0 && <div className="game-imgs-intro" onClick={()=>{setWelfareMored(true);Manager.push({event_id:'D-2-15'})}}>

      <div className="head">
        <div className="subject">
          <div className="gamedesc">游戏福利</div>
          <img style={{margin:'0'}} width='15px' className="right" src='resources/game/more.png'></img>
        </div>
      </div>
      <div className={"game-intro"} ref={introBodyRef}>
        <div className="introduce" dangerouslySetInnerHTML={{
            __html: game.game_feature_list[0].content.replace(/([*\r\n]?)(\r\n|\n\r)/g, '$1<br>$2') 
          }}>
        </div>
      </div>
    </div>}
    
    <div className="game-imgs-intro" onClick={() => {setIntroMored(true);Manager.push({event_id:'D-2-16'})}}>
      <div className="head">
        <div className="subject">
          <div className="title">游戏介绍</div>
          <img style={{margin:'0'}} width='15px' className="right" src='resources/game/more.png'></img>
        </div>
      </div>

      <div className={"game-intro "} ref={introBodyRef}>
        <div className="entity">
          {game.game_introduce}
        </div>
      </div>
    </div>

    <div className="game-imgs-intro">
      <div className="head">
        <div className="subject">
          <div className="title">常见问题</div>
        </div>
      </div>

      <div className={"game-intro "} style={{maxHeight:'fit-content'}}>
        <div className="entity">
          {game?.downloadQuestion?.map(item=>{
            return (
              <NavLink user={false} iframe={{title:item.t}} to={(()=>{
                let url = URI(item.a)
                  .addQuery('location_url', window.location.origin)
                  .addQuery('origin', 'react')
                  .toString();
                return url;
              })()} style={{
                display:'flex',alignItems:"center",justifyContent:'space-between',marginBottom:'5px',color:'#282828'
              }}>
                <span>{item.q}</span>
                <RightOutlined style={{fontSize:'10px',color:'#999'}}></RightOutlined>
              </NavLink>
            )
          })}          
        </div>
      </div>
    </div>

    {welfareMored && <GameWelfare {...props} setWelfareMored={()=>setWelfareMored(false)}></GameWelfare>}
    {introMored ? <GameDesc {...props} setIntroMored={()=>setIntroMored(false)}></GameDesc> :null}


    <div className="game-games">
      <div className="head">
        <div className="text">类似游戏</div>
        <div className="operate" onClick={() => {
          Manager.push({event_id:'D-2-17'})
          recommend()
        }}><SyncOutlined className="icon"/>换一批</div>
      </div>
      <div className="body clearfix">
        {data?.map((game, index) => <Link key={index} to={'/game?id=' + game.game_id} className={"item " + (index == 4 ? ' clear' : '')}>
          <div className="icon"><Image src={game.game_image.thumb} alt=""/></div>
          <div className="name">{game.game_name}</div>
          <div className="info">{game.game_classify_type} </div>
          {/* {
            game.nameRemark ?
            <div className="nameRemark">
              <span>
              {game.nameRemark}
              </span>
              
            </div>
            : null
          } */}
        </Link>)}
      </div>
    </div>
    <NavLink onClick={()=>Manager.push({event_id:'D-2-18'})} to={'/feedback?type=game&id=' + game.game_id} className="game-help">
      游戏有问题？<span>点击反馈</span>
    </NavLink>
  </div>;
};