import {useEffect, useState, useRef} from "react";
import {useLockFn, useMount} from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import {useHistory, useLocation} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import URI from "urijs";
import Image from "../../lib/Image";
import {NavLink} from "../../lib/Navigation";
import MobileDetect from "mobile-detect";
import useUrlState from "@ahooksjs/use-url-state";

export default props => {
  const {aplus_queue} = window;
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [agent] = useActiveState('agent');
  const [card, setCard] = useState({});
  const [datas, setDatas] = useState();
  const [vip, setVip] = useState({});
  const mobileDetect = new MobileDetect(navigator.userAgent);
  const [textTips, setTextTips] = useState([]);

  const [init, setInit] = useState(false);
  const [gameAlert,setGameAlert] = useState(false)
  const [notApplicableList,setNotApplicableList] = useState([])
  const searchRef = useRef()

  // useEffect(() => {
  //   !data && setData(datas.find(item => item.active));
  // }, [datas]);

  const getNotApplicableList = ()=>{
    const search_info = searchRef.current?.value
    general.axios.post('/getNotApplicableList',{tab:'mycard',search_info:search_info,pagination:{page:'1',count:'99999'}})
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      setNotApplicableList(data.list);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  useEffect(()=>{
    if (alert){
      getNotApplicableList()
    }
  },[gameAlert])

  const load = () => {
    general.axios.post('/getMonthCardInfo?noaccess=1')
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      setCard(data);
      setDatas(data.items);
      setData(data.items.find(item => item.active));
      console.log(data);

      if(data.explain) {
        let tip = data.explain.split('\r\n');
        setTextTips(tip);
      }
      // return;
      
      // setCard(data.money_saving_card);
      // setDatas(data.money_saving_card.items);
      // setData(data.money_saving_card.items.find(item => item.active));
      // setVip(data.vip);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  // console.log(user);

  // useMount(() => {
  //   load();
  // });

  useEffect(()=>{
    load();
  }, [user]);

  const urlLogin = () => {
    if(general.isLogin() && localStorage.getItem('username') == params.username) {
      return;
    }

    if (params.userid && params.username && params.token) {
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);

      load_load();
    }
  }
  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })
  useMount(urlLogin);

  const [pay, setPay] = useState();
  const [payUrl, setPayUrl] = useState();
  useEffect(() => {
    if (pay) {
      // if (mobileDetect.is('iPhone')) {
      //   let url = URI('https://sys.wakaifu.com/home/mixPay/' + pay.type)
      //       .addQuery('username', localStorage.getItem('username'))
      //       .addQuery('token', localStorage.getItem('token'))
      //       .addQuery('grade_id', pay.grade_id)
      //       .addQuery('from', 'react');

      //   setPayUrl(url);
      // } else {
      //   let url = URI('https://sys.wakaifu.com/home/mixPay/' + pay.type)
      //       .addQuery('username', localStorage.getItem('username'))
      //       .addQuery('token', localStorage.getItem('token'))
      //       .addQuery('grade_id', pay.grade_id)
      //       .addQuery('from', 'react');

      //   setPayUrl(url);
      // }
      
      let url = URI(card.pay_url)
          .addQuery('username', localStorage.getItem('username'))
          .addQuery('token', localStorage.getItem('token'))
          .addQuery('grade_id', pay.grade_id)
          .addQuery('mb', localStorage.getItem('mb'))
          .addQuery('from', 'react');

      setPayUrl(url);

      setTimeout(()=>{
        setPay(null)
      }, 8000)
    } else {
      setPayUrl(null);
    }
  }, [pay]);

  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  const cardPay = useLockFn(()=>{
    if(!general.isLogin()) {
      PubSub.publish('alert-login',true)
      return;
    }

    if(user.isCheckAuth && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdult && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }
    setPay({type: 'mcard', grade_id: data?.grade_id})

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['BuyMoneySavingCard', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
    });
  })

  //金币领取
  const drawGold = () => {
    Dialog.loading();
    general.axios.post('/drawGold', {grade_id:card?.grade_id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("领取成功");
          load();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  return <div><div className="save-card-new">
    <div className="">
      <img src="/resources/save/new-card-top.png" />
    </div>
    <div className="card-index">

      <div className="card-user-info" >
        <div className="bg">
          <img src="/resources/save/new-card-info.png" />
        </div>
        <div className="bg-text">
          <div className="info-title">
            <div className="icon">
              <Image src={ !general.isLogin() ? '/resources/user/avatar.png' : user.avatar_status === '-1'? '/resources/user/avatar.png' :user.icon_link} alt="个人头像" className="avatar"/>
            </div>
            <div className="info">
              <div className="name">
                {localStorage.getItem('username') ? user?.nick_name : '未登录'}
              </div>
              <div className="desc">
                {card.is_buy ? card.end_date_time : '暂未购买'}
              </div>
            </div>
            <div className="operate">
              {card?.is_buy ? card?.is_blend == 0 ? card?.is_drwa == 1 ? 
              <NavLink onClick={()=>drawGold()} user={true} className="operate-btn">领取50金币</NavLink>
              : <div className="operate-btn operate-btn-already">
                今日已领取
              </div> : null : null}
            </div>
          </div>
          <div className="tips">
            {/* 开通立返全额，每天再领额外赠送！ */}
            {card.info}
          </div>
        </div>
      </div>

      <div className="card-type">
        {datas?.map(item => <div className={'item' + (item.id === data?.id ? ' active' : '')} onClick={() => setData(item)} >
          {item.hot_title ? <div className="good" style={{backgroundImage: 'url(/resources/save/vip-good.png)'}}>{item.hot_title}</div> : null}
          <div className="icon">
            {/* <img src={'/resources/save/' + item.name + '.png'} /> */}
            <img src={item.icon} />
          </div>
          <div className="info">
            <div className="title">
              {item.f_desc}
            </div>
            <div className="desc1">
              {item.t_desc}
            </div>
            <div className="desc2">
              {item.s_desc}
            </div>
            
            {/* <div className="price">￥<span>{item.price}</span></div>
            <div className="value">{item.desc}</div>
            <div className="choose"><img src="/resources/save/card-choose.png" alt=""/></div> */}
          </div>

          <div className="price">
            {item.amount}元
            <s>
              原价{item.old_amount}元
            </s>
          </div>
        </div>)}

        {/* {datas?.map(item => ((vip == 1 && item.active) || vip != 1) ? <div className={'item' + (item.grade_id === data?.grade_id ? ' active' : '')} onClick={() => setData(item)} style={{backgroundImage: 'url(/resources/save/card-item.png)'}}>
          {item.hot_title ? <div className="good" style={{backgroundImage: 'url(/resources/save/vip-good.png)'}}>{item.hot_title}</div> : null}
          <div className="title">{item.title}</div>
          <div className="price">￥<span>{item.price}</span></div>
          <div className="value">{item.desc}</div>
          <div className="choose"><img src="/resources/save/card-choose.png" alt=""/></div>
        </div> : null)} */}
      </div>
      {
        card?.buttonDisplay == 1 ? <div className="card-btn-display">
          已开通
          <p>({card?.buttonTime})</p>
        </div>
        : <div className="card-btn" onClick={cardPay}>{data?.submit_button_title || '立即开通'}</div>
      }
      {/* <div className="card-btn" onClick={cardPay}>{data?.submit_button_title || '立即开通'}</div> */}
      <div className="card-tips">{data?.tips}</div>

      <div className="vip-game" onClick={()=>{
            setGameAlert(true)
          }}>查看省钱卡不可用游戏
        <img src="resources/community/more_grary.png" alt="" width='7px' />
      </div>

      {/* <div className="card-info">
        <div className="title">省钱卡权益</div>
        <div className="items">
          <div className="item">
            <img src="/resources/save/card-1.png" alt=""/>
            满6减6券＊每天1张
          </div>
          <div className="item">
            <img src="/resources/save/card-2.png" alt=""/>
            支持全部BT游戏
          </div>
        </div>
      </div> */}

      <div className="card-explain">
        <div className="title">
          <img src="/resources/save/new-card-sm.png" />
        </div>
        <div className="content">
          {textTips.map(item => <dl>
            <dd>
              {item}
            </dd>
          </dl>)}
          {/* <dl>
            <dd>1、10金币=1RMB，此活动获得的金币仅适用与平台BT游戏内充值抵扣；</dd>
          </dl>
          <dl>
            <dd>
              <p>2、省钱卡有效期内每天可领取50金币，每天返还的金币仅能当天领取，当天忘记领取将错过返还；</p>
            </dd>
          </dl>
          <dl>
            <dd>3、重复购买省钱卡，均可立返金币，但每日金币奖励不可叠加，每天固定只可领50金币；</dd>
          </dl>
          <dl>
            <dd>
            4、重复购买省钱卡，有效时间可叠加（目前最大可叠加时长为6个月）。
            </dd>
          </dl> */}
        </div>
        {/* <div className="tips">
          特别提醒：“省钱卡”为虚拟产品，一经售出不予退款，最终解释权归游戏平台所有
        </div> */}
      </div>

    </div>
  </div>
  {/***实名认证弹框***/}
  {isCheckAuth?
  <div className="pay-check-auth">
      <div className="auth-index">
        <div className="close" onClick={() => setIsCheckAuth(false)}>
          <img src="/resources/user/close.png" />
        </div>
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>实名认证</h3>
            <p>
            {config.authTopTips}
              {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
            </p>
          </div>
        </div>

        <div className="auth-user-realname">
          <div className="auth-user-input">
            <div className="name">真实姓名</div>
            <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
          </div>
          <div className="auth-user-input">
            <div className="name">身份证号</div>
            <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
          </div>
          <span>
          {config.authBottomTips}
          {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
          </span>
          <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
        </div>
      </div>
    </div> : null
    }
    {/** 提示弹框 **/}
    {
    isCheckAdult ? 
    <div className="pay-check-auth">
      <div className="auth-index adult-index">
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>温馨提示</h3>
            <p>
            {config.adultTips}
              {/* 根据国家新闻出版署最新规定，禁止向未成年用户提供支付服务。 */}
            </p>
          </div>
        </div>
        <div className="auth-user-realname">
          <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
        </div>
      </div>
    </div>
    : null
    }

    <CSSTransition in={!!pay} timeout={250} unmountOnExit={true}>
      <div className="save-pay">
        <div className="mask" onClick={() => setPay(null)}/>
        <div className="container">
          <div className="title">支付</div>
          <object data={payUrl}
            width="100%"
            height={user.is_oversea == '2' ? '370px' : '200px'}
            className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') }
            type="text/html">
          </object>
          {/* <iframe className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') }  src={payUrl}/> */}
        </div>
      </div>
    </CSSTransition>

    <CSSTransition in={gameAlert} timeout={250} unmountOnExit={true}>
      <div className="game-alert">
        <div className="mask" onClick={() => {
          setGameAlert(false)
        }}/>
        <div className="container">
          <div className="title">以下游戏不可用<img onClick={()=>setGameAlert(false)} className="close" src="resources/community/close.png"></img></div>
          <div className="header">
            <input ref={searchRef} type="text" placeholder='请输入游戏名'/>
            <div className="search" onClick={()=>{
              getNotApplicableList()
            }}>搜索</div>
          </div>
          {notApplicableList?.length === 0 ? <div className="empty"><img src="/resources/empty.png" alt=""/>暂无数据</div> : null}
          <div style={{overflow:'scroll',height:'83%'}}>
            <div className="games" style={{overflow:'scroll'}}>
              {notApplicableList?.map((item)=>{
                return (
                  <div key={item.id} className="item">
                    <img src={item.game_image.thumb} alt="" />
                    <div className="gamename">{item.game_name}</div>
                    <div className="desc">{item.nameRemark}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>

  </div>;
};