import { Popup } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { NavLink } from '../../lib/Navigation'
import { DownOutlined } from '@ant-design/icons'

export default function GameDesc(props) {
    const [visible,setVisible] = useState(true)
    const [vip,setVip] = useState()
    const [vip_open,setVip_open] = useState(false)

    useEffect(()=>{
        if (props.game.game_feature_list) {
            let result = /<table[^>]*?>([\s\S]*)<\/table>/g.exec(props.game.game_feature_list[2].content);
            if (result && result[0]) {
              setVip(result[0]);
            }
          }
    },[])

    return (
      <Popup className="version-mask-index"
          visible={visible}
          onMaskClick={() => {
              setVisible(false)
              props.setIntroMored()
          }}
          bodyStyle={{
            borderTopLeftRadius:'13px',
            borderTopRightRadius:'13px',
            overflow: 'hidden',
          }}>
          <div style={{maxHeight:'75vh',overflowY:'scroll',backgroundColor:'#F5F6F8',padding:'15px'}}>
              {props.game.game_introduce && <div style={{backgroundColor:'#fff',borderRadius:'13px',padding:'15px'}}>
              <div style={{display:'flex',alignItems:'center'}}>
                  <span style={{fontSize:'17px',fontWeight:'500',color:'#383838'}}>游戏介绍</span>
              </div>
              <div style={{color:'#666',fontSize:'13px',paddingTop:'10px'}} 
                  dangerouslySetInnerHTML={{
                      __html:props.game.game_introduce.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r)/g, '$1<br>$2')}}></div>
              </div>}
  
              {vip && <div style={{backgroundColor:'#fff',borderRadius:'13px',padding:'15px',marginTop:'15px',position:'relative'}}>
                <div style={{display:'flex',alignItems:'center'}} >
                    <span style={{fontSize:'17px',fontWeight:'500',color:'#383838'}}>VIP表</span>
                </div>
                <div className={'game-vip ' + (vip_open ? '' : 'close')} dangerouslySetInnerHTML={{__html: vip}}></div>
                <div onClick={()=>setVip_open(true)} style={{opacity:(vip_open ? '0' : '1'),position:'absolute',bottom:'15px',left:'15px',right:'15px',height:'100px',background:'linear-gradient(to bottom, transparent , #f6f6f6)',display:'flex',justifyContent:'flex-end',alignItems: 'flex-end'}}>
                    <DownOutlined style={{color:'#eb424c',padding:'5px'}}></DownOutlined>
                </div>
              </div>}

              {props.game.companyInfo?.company && <div style={{backgroundColor:'#fff',borderRadius:'13px',padding:'15px',marginTop:'15px'}}>
                <div style={{display:'flex',alignItems:'center'}} >
                    <span style={{fontSize:'17px',fontWeight:'500',color:'#383838'}}>厂商信息</span>
                </div>
                <div style={{color:'#666',fontSize:'13px',paddingTop:'10px'}}>供应商信息：{props.game.companyInfo?.company}</div>
                {props.game.companyInfo?.filingCode && <div style={{color:'#666',fontSize:'13px',paddingTop:'5px'}}>软件备案码：{props.game.companyInfo?.filingCode}</div>}
                {props.game.appVersion && <div style={{color:'#666',fontSize:'13px',paddingTop:'5px'}}>软件版本：{props.game.appVersion}</div>}
                {
                    props.game.companyInfo?.roleUrl && (props.game.companyInfo?.roleUrl.indexOf('https') == -1 ?
                    <div style={{color:'#666',fontSize:'13px',paddingTop:'5px'}} onClick={()=>{
                        props.setIntroMored()
                        window.open(props.game?.companyInfo?.roleUrl, "_blank")
                    }}>
                        权限信息：
                        <span style={{color:'#eb424c'}}>查看详情</span>
                    </div>
                    :
                    <div style={{color:'#666',fontSize:'13px',paddingTop:'5px'}} onClick={()=>{
                        setVisible(false)
                        props.setIntroMored()
                    }}>
                        权限信息：
                        <NavLink style={{color:'#eb424c'}} to={props.game.companyInfo?.roleUrl} iframe={{title:"权限信息"}} user={false}>查看详情</NavLink>
                    </div>)
                }
                {
                    props.game.companyInfo?.privacyUrl && (props.game.companyInfo?.privacyUrl.indexOf('https') == -1 ?
                    <div style={{color:'#666',fontSize:'13px',paddingTop:'5px'}} onClick={()=>{
                        props.setIntroMored()
                        window.open(props.game?.companyInfo?.privacyUrl, "_blank")
                    }}>
                        隐私政策：
                        <span style={{color:'#eb424c'}}>查看详情</span>
                    </div>
                    :
                    <div style={{color:'#666',fontSize:'13px',paddingTop:'5px'}} onClick={()=>{
                        setVisible(false)
                        props.setIntroMored()
                    }}>
                        隐私政策：
                        <NavLink style={{color:'#eb424c'}} to={props.game.companyInfo?.privacyUrl} iframe={{title:"隐私政策"}} user={false}>查看详情</NavLink>
                    </div>)
                }
              </div>}
  
          </div>
      </Popup>
    )
}
