import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useScroll, useMount, useLockFn} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from '../../../lib/useActiveState';
import {CSSTransition} from "react-transition-group";
import MobileDetect from "mobile-detect";
import moment from "moment";
import URI from "urijs";
import {NavLink} from "../../../lib/Navigation";
import { CloudUrl } from "../../../env";
import Subtitle from "../../components/Subtitle";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const {aplus_queue} = window;
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [init, setInit] = useState(false);
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [udid] = useActiveState('udid');
  let [mb] = useActiveState('mb');
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios.post('/base/game/getAuthorityGame', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        setObsolete(false);
        parms.page && setPage(parms.page);
        let {game_list: list, paginated} = data;

        list.forEach(d => {
          d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
        })

        setGames(games => parms.page === 1 || !parms.page ? list : [...games, ...list]);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  //埋点
  const clickGameOfTheRankList = (game) => {
    // aplus_queue.push({
    //   action: 'aplus.record',
    //   arguments: ['ClickGameOfTheRankList', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), tabname: '热门榜', gamename:game.game_name, agent: agent}]
    // });
  }

  const [waitRoamState, setWaitRoamState] = useState(false);//等待排队弹框
  const [startRoamState, setStartRoamState] = useState(false);//开始云玩弹框
  const [cloudappCheckAvailableUrl, setCloudappCheckAvailableUrl] = useState();//检测是否有设备
  const [cloudappIndexUrl, setCloudappIndexUrl] = useState();//跳转
  const [cloudData, setCloudData] = useState([]);

  //判断有没有云主机--秒玩
  const isroam = useLockFn((game) => {
    // console.log(game);
    //实名认证弹框
    // if(user.isCheckAuth && !user.isRealNameAuth) {
    //   setIsCheckAuth(true);
    //   return;
    // }
    // if(user.isCheckAdult && !user.isAdult) {
    //   setIsCheckAdult(true);
    //   return;
    // }

    // clickToCloudGames();
    // console.log(config.cloudappIndexUrl);
    // window.location.href=config.cloudappIndexUrl+"?gameid=" + game.maiyou_gameid + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id');
    Dialog.loading();
    general.axios.get(game.cloudappCheckAvailableUrl + "?gameid=" + game.maiyou_gameid + "&username=" + localStorage.getItem('username'))
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          // console.log(data.isValid);
          if(data.isValid == '1') {
            setStartRoamState(true);
          } else {
            setWaitRoamState(true);
          }
          setCloudData(game);
          setCloudappCheckAvailableUrl(game.cloudappCheckAvailableUrl);
          setCloudappIndexUrl(game.cloudappIndexUrl);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

   //添加至桌面
   const isDeskCloudApp = () => {
    //http://gamebox.sxswyc.top/
    //https://webbox.mlaps0705.com
    //https://cloudgame.i52wan.com/#/ -- i52wan
    //https://cloudgame.heigu.com/#/ -- 嘿咕
    // let hrefs = "https://cloudgame.i52wan.com/#/?gameid=" + cloudData?.maiyou_gameid +"&gamename="+cloudData?.game_name+"&icon=" + cloudData?.game_image?.thumb + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&agent=" + agent + "&time="+moment().unix() + "&bookmarkId=2904878F-67C5-5559-17C4-897EF81BB3AE";
    // window.open(hrefs, "_blank")

    // if(agent == 'wg3n' || agent == '19ab' || agent == '0zme' || agent == 's7y0' || agent == 'i1sd' || agent == '9d6f' || agent == '1zph' || agent == 'tz7y' || agent == 'c6mq') {
    if(mb == 1 || (agent == 'wg3n' || agent == 'i1sd' || agent == '9d6f' || agent == 'c6mq')) {
      let hrefs = CloudUrl + "?gameid=" + cloudData?.maiyou_gameid +"&gamename="+cloudData?.game_name+"&icon=" + cloudData?.game_image?.thumb + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&agent=" + agent + "&time="+moment().unix() + "&udid="+udid+"&user_id="+localStorage.getItem('user_id');

      let hrefUrl = URI('app://browser/')
              .addQuery('url', hrefs).toString();
        
      window.location.href = hrefUrl;
    } else {
      let hrefs = CloudUrl + "?gameid=" + cloudData?.maiyou_gameid +"&gamename="+cloudData?.game_name+"&icon=" + cloudData?.game_image?.thumb + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&agent=" + agent + "&time="+moment().unix() + "&udid="+udid+"&user_id="+localStorage.getItem('user_id');
      window.open(hrefs, "_blank")
    }
  }

  return <div className="authority-game">
    <div className="list-body">
      {games.length > 0 ? <>
        <div className="game-ority-list">
          {games.map((game, index) => <div className="game-ority-list-item" onClick={()=>isroam(game)}>
            <div className="icon">
              <Image src={game.game_image.thumb}/>
            </div>
            <div className="text">
              <div className="name">
                {game.game_name}
              </div>
              
              {/* <div className="nameRemarkInfo">{game.nameRemark}</div> */}
              <div className="info1">
                {game.game_classify_type}
                <Subtitle>{game.nameRemark}</Subtitle>
              </div>
            </div>
            {/* {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null} */}
            
            <div className="operate-play" >
              秒玩
            </div>
          </div> )}
        </div>
      </> : null}
      {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
      {games.length === 0 && init ? <div className="recovery-empty"><img src="/resources/user/game_empty.png" alt=""/>暂无云游记录</div> : null}
    </div>

    {/** start **/}
    <CSSTransition in={startRoamState} timeout={250} unmountOnExit={true}>
      <div className="game-roam-detail">
        <div className="roam-mask" />
        <div className="roam-container">
          <div className="roam-top">
            <div className="close" onClick={()=>setStartRoamState(false)}>
              <img src="/resources/party/couple-close.png" />
            </div>
          </div>

          <div className="roam-tips">
            云游戏说明
          </div>
          <div className="roam-tips2">
            <p>1.免费云游，极速畅玩；</p>
            <p>2.无需签名，无需付费无需下载等待；</p>
            {/* <p>3.玩的游戏找不到了？首页点击icon云玩记录即可查看云玩历史进入游戏。</p>
            <p>4.推荐选择“添加到桌面”方式，即能快速从手机桌面直接打开，无需打开游戏盒子。</p> */}
            <p>3.添加到桌面后，即能直接从手机桌面进入。</p>
            {
              cloudData?.is_both == 0 ?
              <p style={{color:'red',fontSize:'.24rem',fontWeight:'bold'}}>注：云游所产生的数据属于安卓端，无法与IOS数据互通；若您之前在IOS游戏包有角色，请继续下载IOS游戏包进行游戏。</p>
              : null
            }

          </div>

          {/** gamefullscreen 是否隐藏title的标识 **/}

          {
            cloudData?.cloudappIndexUrl ? 
              // <NavLink className="roam-bottom"
              //   to={(()=>{
              //     let url = URI(cloudData?.cloudappIndexUrl)
              //       .addQuery('gameid', cloudData.maiyou_gameid)
              //       .addQuery('username', localStorage.getItem('username'))
              //       .addQuery('token', localStorage.getItem('token'))
              //       .addQuery('userid', localStorage.getItem('user_id'))
              //       .addQuery('source', 'react')
              //       .addQuery('origin', 'react')
              //       .addQuery('time', moment().unix())
              //       .addQuery('location_url', encodeURIComponent(window.location.origin + '/#/game?id='+cloudData.game_id))
              //       .toString();
              //     return url;
              //   })()}
              //   iframe={{title: '云玩', gamefullscreen:'1'}}
              //   onClick={()=>setStartRoamState(false)}>
              //   立即开始
              // </NavLink>
              <div className="operate-cloud-app">
                <NavLink className="roam-bottom"
                  to={(()=>{
                    let url = URI(cloudData?.cloudappIndexUrl)
                      .addQuery('gameid', cloudData.maiyou_gameid)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('userid', localStorage.getItem('user_id'))
                      .addQuery('source', 'react')
                      .addQuery('origin', 'react')
                      .addQuery('time', moment().unix())
                      .addQuery('gameicon', cloudData.game_image?.thumb)
                      .addQuery('gamename', cloudData.game_name)
                      .addQuery('nameremark', cloudData.nameRemark)
                      .addQuery('location_url', encodeURIComponent(window.location.origin + '/#/game?id='+cloudData.game_id))
                      .toString();
                    return url;
                  })()}
                  iframe={{title: '云玩', gamefullscreen:'1'}}
                  onClick={()=>setStartRoamState(false)}>
                  立即开始
                </NavLink>

                <div className="roam-bottom daskapp-game" onClick={()=>isDeskCloudApp()}>
                  添加即玩
                </div>
              </div>
            : null
          }

            
        </div>
      </div>
    </CSSTransition>

    <CSSTransition in={waitRoamState} timeout={250} unmountOnExit={true}>
      <div className="game-roam-detail">
        <div className="roam-mask" />
        <div className="roam-container">
          <div className="roam-top">
            <div className="close" onClick={()=>setWaitRoamState(false)} >
              <img src="/resources/party/couple-close.png" />
            </div>
          </div>

          <div className="roam-tips">
            云游戏说明
          </div>
          <div className="roam-tips2">
            <p>1.免费云游，极速畅玩；</p>
            <p>2.无需签名，无需付费无需下载等待。</p>
          </div>
          <div className="roam-tips3">
            排队中...
          </div>
          <div className="roam-tips4">
            如需更稳定流畅的体验，推荐下载至尊版。
          </div>
          <div className="roam-bottom-download" onClick={() => {upgrade()}}>
            至尊版下载
          </div>
          <div className="roam-waiting" onClick={()=>setWaitRoamState(false)}>继续等待</div>
        </div>
      </div>
    </CSSTransition>
  </div>;
};