import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Provider, useStateContext,ChatContainer,NimKitCoreFactory,useTranslation } from '@xkit-yx/im-kit-ui'
import general from '../../../../general';
import Dialog from '../../../../lib/Dialog';
import {Link} from "react-router-dom";
import { createFromIconfontCN } from '@ant-design/icons'
import reactStringReplace from 'react-string-replace'
import { CSSTransition } from 'react-transition-group';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Popover } from 'antd-mobile';
import "@xkit-yx/im-kit-ui/es/style/css"
import './css/Chat.css'

export default function IMChat(props) {

  const { t } = useTranslation()
  const {store,nim} = useStateContext()
  const [title,setTitle] = useState()
  const [announcement,setAnnouncement] = useState(null)
  const [pack_serialcode,set_pack_serialcode] = useState('')
  const [alert,setAlert] = useState(false)
  const [unreadCounts, setUnreadCounts] = useState({});
  const [gameid,setGameid] = useState()

  const outAction = [
    { key: 'recall', text: '撤回' },
    { key: 'delete', text: '删除' },
    { key: 'copy', text: '复制' }
  ] 
  const inAction = [
    { key: 'reply', text: '回复'},
    { key: 'delete', text: '删除' },
    { key: 'copy', text: '复制' }
  ] 

  const renderTeamInputPlaceHolder = (options) => {
    return "和大家说点什么吧"
  }

  const handleEmojiTranslate = (t) => {
    const EMOJI_ICON_MAP_CONFIG = {
      [t('Laugh')]: 'icon-a-1',
      [t('Happy')]: 'icon-a-2',
      [t('Sexy')]: 'icon-a-3',
      [t('Cool')]: 'icon-a-4',
      [t('Mischievous')]: 'icon-a-5',
      [t('Kiss')]: 'icon-a-6',
      [t('Spit')]: 'icon-a-7',
      [t('Squint')]: 'icon-a-8',
      [t('Cute')]: 'icon-a-9',
      [t('Grimace')]: 'icon-a-10',
      [t('Snicker')]: 'icon-a-11',
      [t('Joy')]: 'icon-a-12',
      [t('Ecstasy')]: 'icon-a-13',
      [t('Surprise')]: 'icon-a-14',
      [t('Tears')]: 'icon-a-15',
      [t('Sweat')]: 'icon-a-16',
      [t('Angle')]: 'icon-a-17',
      [t('Funny')]: 'icon-a-18',
      [t('Awkward')]: 'icon-a-19',
      [t('Thrill')]: 'icon-a-20',
      [t('Cry')]: 'icon-a-21',
      [t('Fretting')]: 'icon-a-22',
      [t('Terrorist')]: 'icon-a-23',
      [t('Halo')]: 'icon-a-24',
      [t('Shame')]: 'icon-a-25',
      [t('Sleep')]: 'icon-a-26',
      [t('Tired')]: 'icon-a-27',
      [t('Mask')]: 'icon-a-28',
      [t('ok')]: 'icon-a-29',
      [t('AllRight')]: 'icon-a-30',
      [t('Despise')]: 'icon-a-31',
      [t('Uncomfortable')]: 'icon-a-32',
      [t('Disdain')]: 'icon-a-33',
      [t('ill')]: 'icon-a-34',
      [t('Mad')]: 'icon-a-35',
      [t('Ghost')]: 'icon-a-36',
      [t('Angry')]: 'icon-a-37',
      [t('Angry')]: 'icon-a-38',
      [t('Unhappy')]: 'icon-a-39',
      [t('Frown')]: 'icon-a-40',
      [t('Broken')]: 'icon-a-41',
      [t('Beckoning')]: 'icon-a-42',
      [t('Ok')]: 'icon-a-43',
      [t('Low')]: 'icon-a-44',
      [t('Nice')]: 'icon-a-45',
      [t('Applause')]: 'icon-a-46',
      [t('GoodJob')]: 'icon-a-47',
      [t('Hit')]: 'icon-a-48',
      [t('Please')]: 'icon-a-49',
      [t('Bye')]: 'icon-a-50',
      [t('First')]: 'icon-a-51',
      [t('Fist')]: 'icon-a-52',
      [t('GiveMeFive')]: 'icon-a-53',
      [t('Knife')]: 'icon-a-54',
      [t('Hi')]: 'icon-a-55',
      [t('No')]: 'icon-a-56',
      [t('Hold')]: 'icon-a-57',
      [t('Think')]: 'icon-a-58',
      [t('Pig')]: 'icon-a-59',
      [t('NoListen')]: 'icon-a-60',
      [t('NoLook')]: 'icon-a-61',
      [t('NoWords')]: 'icon-a-62',
      [t('Monkey')]: 'icon-a-63',
      [t('Bomb')]: 'icon-a-64',
      [t('Sleep')]: 'icon-a-65',
      [t('Cloud')]: 'icon-a-66',
      [t('Rocket')]: 'icon-a-67',
      [t('Ambulance')]: 'icon-a-68',
      [t('Poop')]: 'icon-a-70',
    }
    // TODO react-string-replace 的行为不是那么的好理解，比如像下面这个正则就一定要加 '()'，后面最好干掉自己实现
    const INPUT_EMOJI_SYMBOL_REG = new RegExp(
      '(' +
        Object.keys(EMOJI_ICON_MAP_CONFIG)
          .map((item) => {
            const left = `\\${item.slice(0, 1)}`
            const right = `\\${item.slice(-1)}`
            const mid = item.slice(1, -1)
            return `${left}${mid}${right}`
          })
          .join('|') +
        ')',
      'g'
    )
    return {
      EMOJI_ICON_MAP_CONFIG,
      INPUT_EMOJI_SYMBOL_REG,
    }
  }

  const CommonIcon = createFromIconfontCN({
    scriptUrl: [
      '//yx-web-nosdn.netease.im/sdk-release/yunxun-imweb-iconfont.js',
      '//at.alicdn.com/t/c/font_3429868_blvaz7v7n6p.js',
    ], // 在 iconfont.cn 上生成
  })

  const onMessageAction = (action,msg) => {
    if (action.key === "recall") {
      store.msgStore.reCallMsgActive(msg)
    }else if (action.key === 'delete'){
      store.msgStore.deleteMsgActive([msg])
    }else if (action.key === 'copy'){
      var textField = document.createElement('textarea')
      textField.innerText = msg.body
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      Dialog.info('已复制到剪贴板')
    }else if (action.key === 'reply') {
      store.msgStore.replyMsgActive(msg)
    }
  }

  const renderMessageOuterContent = (msg) => {

    const { body, idClient, ext, type ,flow, custom } = msg
    if (type === 'text'){
      let teamText = ''
      let replyText = ''
      if (custom){
        const yxAitMsg = JSON.parse(custom)
        Object.keys(yxAitMsg).map(key=>{
          const users = yxAitMsg[key]
          Object.keys(users).map(userkey=>{
            const user = users[userkey]
            teamText = user['text']
          })
        })
        if (yxAitMsg.yxReplyMsg){
          const replyMsg = store.msgStore.getMsg(store.uiStore.selectedSession,[yxAitMsg.yxReplyMsg.idClient])
          if (replyMsg.length > 0){
            replyText = '| ' + replyMsg[0].fromNick + ': ' + replyMsg[0].text
          }
        }
      }

      const prefix = 'common'
      const _prefix = `${prefix}-parse-session`
      const { EMOJI_ICON_MAP_CONFIG, INPUT_EMOJI_SYMBOL_REG } =
        handleEmojiTranslate(t)
  
      let text = reactStringReplace(body.replace(teamText,''), /(https?:\/\/\S+)/gi, (match, i) => (
        <a key={idClient + match + i} href={match} target="_blank">
          {match}
        </a>
      ))
      text = reactStringReplace(text, INPUT_EMOJI_SYMBOL_REG, (match, i) => {
        return (
          <CommonIcon
            key={idClient + match + i}
            className={`${_prefix}-emoji-icon`}
            type={EMOJI_ICON_MAP_CONFIG[match]}
          />
        )
      })
      if (ext) {
        try {
          const extObj = JSON.parse(ext)
          const yxAitMsg = extObj.yxAitMsg
          if (yxAitMsg && localOptions.needMention) {
            Object.keys(yxAitMsg).forEach((key) => {
              const item = yxAitMsg[key]
              text = reactStringReplace(text, item.text, (match, i) => {
                return (
                  <span
                    key={idClient + match + i}
                    className={`${_prefix}-mention`}
                  >
                    {match}
                  </span>
                )
              })
            })
          }
        } catch {}
      }
      return <>
          <Popover.Menu 
            trigger='click'
            actions={(()=>{
              const nowTime = Date.parse(new Date())
              if (nowTime - msg.time < 120000 && flow === 'out') {
                return outAction
              }else{
                return inAction
              }
            })()}
            mode='dark'
            placement='top'
            onAction={(action)=>onMessageAction(action,msg)}>
              <div className={`${_prefix}-text-wrapper`} style={{
                padding:'10px',
                borderRadius:'8px',
                color:'#282828',
                fontSize:'.3rem',
                backgroundColor:msg.flow === 'in' ? 'white' : '#FDF9ED'
              }}>
              {replyText.length > 0 && <div style={{fontSize:'12px',color:'#666',padding:'5px 0 10px',borderBottom:'0.5px solid #dfdfdf',marginBottom:'10px'}}>{replyText}</div>}
              <span className={`${_prefix}-mention`}>{teamText}</span>
              {text}</div>
            </Popover.Menu>
        </>
    }
  }

  useEffect(()=>{
    if (props.active){
      props.setOperate(<div>
        <Link to={`/announcement?tid=${props.query.tid}`}>
          <img src="resources/im/gonggao.png" style={{width:'20px'}}/>
        </Link>
        <Link to={`/teamSetting?tid=${props.query.tid}`} >
          <img src="resources/im/setting.png" style={{width:'20px',marginLeft:'8px'}}/>
        </Link>
      </div>);
    }
  },[props.active])

  useEffect(()=>{
    props.setTitle(title)
  },[title])

  useEffect(()=>{
    if (props.active){
      gotoChat()
      getTeamInfo()
    }
    return ()=>{
      store.uiStore.selectedSession = undefined
    }
  },[props.active])

  const gotoChat = () => {
    const sessionId = `team-${props.query.tid}`
    store.uiStore.selectedSession = sessionId
    setTimeout(() => {
      const textarea = document.getElementsByClassName('ant-input')[0]
      if (textarea){
        textarea.setAttribute('enterkeyhint','send')
      }
      window.scrollTo(0,document.documentElement.scrollHeight)
    }, 2000);
  }

  const getTeamInfo = ()=>{
    general.axios.post('/base/yunxin/getClusterInfo',{tid:props.query.tid})
    .then(res=>{
      const {data,status} = res.data
      if (status.succeed){
        setTitle(data.info.tname)
        setAnnouncement(data.info.announcement.list)
        set_pack_serialcode(data.info.pack_serialcode)
        setGameid(data.info.gameid)
      }else{
        Dialog.error(status.error_desc)
      }
    })
  }

  useEffect(()=>{
    if (pack_serialcode.length > 0 && props.active){
      setAlert(true)
    }
  },[pack_serialcode,props.active])

  const handleService = ()=>{
    general.history.push('/service')
  }

  const handleQuestion = ()=>{
    general.history.push(`/game?id=${gameid}&page=2`)
  }

  return (
    <div className='chat-index'>
      {announcement && announcement.length > 0 ?
      <div className='chat-announcement' style={{display:announcement.length > 0 && announcement[0].is_read === false? '' : 'none'}} onClick={()=>{
        general.history.push(`/announcement?tid=${props.query.tid}&open=true`)
      }}>
        <img src="resources/im/laba.png" />
        <span className='content'>{announcement[0].title}</span>
      </div> : null}  
      <ChatContainer 
        renderEmpty={()=>null}
        msgOperMenu={[]}
        renderTeamInputPlaceHolder={renderTeamInputPlaceHolder}
        renderHeader={()=>null}
        renderMessageOuterContent={renderMessageOuterContent}
      />
      <div className='service'>
        <div className='service-container' onClick={handleService}>
          <img src="resources/im/service.png" alt="" />
          <span>联系客服</span>
        </div>
        {gameid && <div className='service-container' onClick={handleQuestion}>
          <img src="resources/im/question.png" alt="" />
          <span>游戏问答互助</span>
        </div>}
      </div>
      <CSSTransition in={alert} timeout={250} unmountOnExit={true}>
        <div className="im-pack-alert">
          <div className="im-pack-mask" onClick={() => setAlert(false)}/>
          <div className="im-pack-container">
          <div className="title">恭喜获得群聊入群礼包！</div>
          <div className="pack">{pack_serialcode}</div>
          <CopyToClipboard text={pack_serialcode} onCopy={() => Dialog.info('已复制礼包码，可前往我的礼包查看！')}>
            <div className="done" onClick={()=>{
              setAlert(false)
            }}>我知道了</div>
          </CopyToClipboard>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}
