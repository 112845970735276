import React, {useCallback, useEffect, useState} from "react";
import Good from "./type/Good";
import Type from "./type/Type";
import general, {redux} from "../../general";
import KeepScroll from "../../lib/KeepScroll";
import $ from "jquery";
import Dialog from "../../lib/Dialog";
import Active from "../../lib/active/Active";
import {useMount} from "ahooks";
import useActiveState from '../../lib/useActiveState';
import Manager from "../components/Manager";

export default ({active, type}) => {
  const {aplus_queue} = window;
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [init, setInit] = useState(false);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState('good');

  const load = () => {
    // Dialog.loading();
    // location [1:发现， 2：开服， 3：交易]
    let tmp = {};
    tmp.location = 1
    general.axios.get('/base/game/getClassifyList', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let tags = data.game_classify_list[0].sub_classify_list[0];
        setTags(tags.map(type => ({id: type.game_classify_id, name: type.game_classify_name,event_id:type.event_id})));
        setInit(true);
        setTag(tags[0].game_classify_id);
        selectOption();
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  // useMount(() => load());

  useEffect(() => {
    if (active && !init) {
      load();
    }
  }, [active, init]);

  const tagRef = useCallback(node => {
    if (node) {
      if (
        node.parentNode.scrollTop + node.parentNode.offsetHeight < node.offsetTop
        || node.parentNode.scrollTop + node.parentNode.offsetHeight + node.offsetHeight > node.offsetTop
      ) {
        $(node.parentNode).animate({scrollTop: node.offsetTop - node.parentNode.offsetHeight / 2}, 555);
        node.parentNode.scrollTop = node.offsetTop - node.parentNode.offsetHeight / 2;
      }
    }
  }, []);

  const selectOption = () => {
    let tag = redux.getState().screenRouter?.tag;
    if (tag) {
      setTag(tag);
    }

    // redux.subscribe(() => {
    //   let tag = redux.getState().screenRouter?.tag;

    //   if (tag) {
    //     setTag(tag);
    //   }
    // });
  }

  useEffect(() => {
    selectOption();
  }, []);

  useEffect(() => {
    redux.subscribe(() => {
      let tag = redux.getState().screenRouter?.tag;
      if (tag) {
        setTag(tag);
      }
    });
  }, []);

  useEffect(() => {
    selectOption();
  }, [tags]);

  //分类点击 埋点
  const clickTheCategories = (item) => {
    Manager.push({event_id:item.event_id})
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTheCategories', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), typename: item, agent: agent}]
    });
  }

  // console.log(tag);
  // console.log(tags);
  // console.log(redux.getState().screenRouter);

  //圆角ID处理
  const [routerIndex, setRouterIndex] = useState(0);
  return <div className="game-index-type">
    <div className="types">
      {/* <div className={'type ' + (tag == 'good' ? 'active ' : '') + (routerIndex - 1 == -1 ? 'prevItem ' : '')} onClick={() => {setTag('good');setRouterIndex(-1);clickTheCategories('精选')}}>精选</div> */}
      {tags.map((item,index) => <div key={index}
        className={'type ' + (tag == item.id ? 'active ' : '') + (routerIndex - 1 == index ? 'prevItem ' : (routerIndex + 1 == index ? 'nextItem ' : ''))}
        onClick={() => {setTag(item.id);setRouterIndex(index);clickTheCategories(item.name)}}
        ref={type == item.id ? tagRef : null}
      >
      <div className="title">{item.name}</div> 
      <div className="line" style={{width:(item.name.length===2? "30px":"45px")}}></div>
      </div>)}
    </div>
    <div className="mains">
      <div className={'main ' + ((tag == 'good' || tag == '1000') ? 'active' : '')}>
        {type == 1
          ? <Active active={active && (tag == 'good' || tag == '1000')}>
            <Good active={active && (tag == 'good' || tag == '1000')} type={type} setTag={setTag}/>
          </Active>
          : <Active active={active && (tag == 'good' || tag == '1000')}>
            <Type active={active && (tag == 'good' || tag == '1000')} type={type}/>
          </Active>
        }
      </div>
      {tags.map((item,index) => <div key={index} className={'main ' + ((tag == item.id && tag != '1000') ? 'active' : '')}>
        <Active active={active && (tag == item.id && tag != '1000')}>
          <Type active={active && (tag == item.id && tag != '1000')} type={type} tag={item.id}/>
        </Active>
      </div>)}
    </div>
  </div>;
};