import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import general from "../../general";
import MobileDetect from "mobile-detect";
import Dialog from "../../lib/Dialog";
import ImagePicker from "../util/ImagePicker";

const mobileDetect = new MobileDetect(navigator.userAgent);

const type = [
  '色情内容',
  '暴力内容',
  '反动内容',
  '侵犯版权',
  '携带病毒',
  '恶意扣费',
  '含有不良插件',
  '无法安装或重启',
  '其他',
];

export default props => {
  const [data, setData] = useState({
    mobile_model: mobileDetect.os() + ' ' + mobileDetect.version(mobileDetect.os()),
    mobile_system_code: mobileDetect.os() + ' ' + mobileDetect.version(mobileDetect.os()),
  });
  useEffect(() => {
    setData({
      ...data,
      game_id: general.game?.gameid,
      game_name: general.game?.gamename,
    });
  }, [props.active]);
  const [imgs, setImgs] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const submit = () => {
    if (submiting) {
      return;
    }
    setSubmiting(true);
    Dialog.loading();
    var formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    imgs.forEach((img, index) => {
      formData.append('imgs[' + index + ']', img.file);
    });
    general.axios.post('/base/feedback', formData)
        .finally(() => {
          setSubmiting(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            Dialog.success('提交成功');
            setTimeout(() => general.history.goBack(), 1000);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    console.log(imgs);
  }, [imgs]);

  return <div className="feedback-game">
    <div className="feedback-game-body">
      <div className="feedback-block">
        <div className="input-item input-device">
          <div className="name">手机型号</div>
          <div className="value">
            {/* {data.mobile_model} */}
            {mobileDetect.phone()}
          </div>
        </div>
        <div className="input-item input-game">
          <div className="name">游戏名称</div>
          <Link to="/game/select" className="value">
            {data.game_name ? data.game_name : '请选择要投诉的游戏 >'}
          </Link>
        </div>
      </div>
      <div className="feedback-block">
        <div className="input-item">
          <div className="name">联系QQ</div>
          <div className="value">
            <input type="text" placeholder="必填" onChange={e => setData({...data, qq: e.target.value})}/>
          </div>
        </div>
        <div className="input-item">
          <div className="name">联系电话</div>
          <div className="value">
            <input type="text" placeholder="非必填" onChange={e => setData({...data, contact: e.target.value})}/>
          </div>
        </div>
        <div className="input-item input-type">
          <div className="name">反馈类型（必填）</div>
          <div className="value clearfix">
            {type.map((type, index) => <label>
              <input name="type" type="radio" value={index} onChange={e => setData({...data, feedback_type: e.target.value})}/>
              {type}
            </label>)}
          </div>
        </div>
        <div className="input-item input-content">
          <textarea
              name="content"
              rows="4"
              placeholder="请描述您在玩游戏中遇到的问题或向我们提出修改建议，我们会尽快进行处理！"
              onChange={e => setData({...data, content: e.target.value})}
          />
        </div>
      </div>
      <div className="feedback-block">
        <div className="name">请上传3~5张图片</div>
        <ImagePicker multiple={true} max={5} files={imgs} setFiles={setImgs}/>
      </div>
    </div>
    <div className="feedback-operate">
      <div className={'btn ' + (submiting ? 'disable' : '')} onClick={submit}>确认提交</div>
    </div>
  </div>;
};